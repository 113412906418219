<template>
  <div class="feed-title">
    <p class="feed-title-top">{{ title }}</p>
    <p class="feed-title-sub" v-if="subtitle">{{ subtitle }}</p>
  </div>
</template>

<script>
export default {
  props: ["title", "subtitle"],
};
</script>

<style lang="scss" scoped>
.feed-title {
  margin: 0 0 $mpadding 0;
  .feed-title-top {
    font-size: 120%;
    margin: 0 0 $mpadding/4 0;
  }
  .feed-title-sub {
    opacity: 0.7;
  }
}
</style>
